import React from 'react';
import './OrderList.styles.css';
import { createColumnHelper } from '@tanstack/react-table';
import { TableComponent } from '../table/Table.component';
import { ApiServer } from '../../services/api';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { binValueAtom, currentStoredData, currentUserAtom, inputAtom, lineItemFlagReasonsAtom, scanalyticsAtom, selectedPrinterAtom, showLineItemFlagModalAtom, showUnscanConfirmModalAtom } from '../../state';
import { Flag } from './../../images/flag';
import { Select } from '../Select/Select.component';
import { Button } from '../button/Button.component';
import { Modal } from '../modal/Modal.component';
import { showSnackbarAtom, snackbarMessageAtom } from '../snackbar/Snackbar.component';


const SplitIdComponent = (props) => {
    const { info } = props;
    
    const setInputValue = useSetAtom(inputAtom);
    const setCurrentBin = useSetAtom(binValueAtom);
    const setCurrentData = useSetAtom(currentStoredData);
    const currentPrinter = useAtomValue(selectedPrinterAtom);
    const setScanalytics = useSetAtom(scanalyticsAtom);
    const currentUser = useAtomValue(currentUserAtom);
    const setShowUnscanConfirmModal = useSetAtom(showUnscanConfirmModalAtom);

    const callTrigger = () => {
        setInputValue(info.getValue());
        const customAttributes = info.row.original.customAttributes;
        if (customAttributes.includes('Scanned')) {
            setShowUnscanConfirmModal({ data: info.getValue(), value: true});
        } else {
            ApiServer.triggerWebhook(info.getValue(), setCurrentData, setCurrentBin, currentPrinter, currentUser.id, setScanalytics);
        }
    }
    return (
        <div onClick={callTrigger} className='SplitId'>
            {info.getValue()}
        </div>
    )
}

const ScannedBoxComponent = (props) => {
    const { info } = props;
    const setShowLineItemFlagModal = useSetAtom(showLineItemFlagModalAtom);

    const flagClick = (row) => {
        setShowLineItemFlagModal({ data: row, value: true });
    }

    return (
        <div className='Icons'>
            <div className='ScannedIcon'>
                {info.getValue() 
                    ? <div className='CheckedIcon'></div>
                    : <div className='UncheckedIcon'></div>}
            </div>
            <span className="ScannedIcon" style={{ cursor: 'pointer' }} onClick={() => flagClick(info.row.original)}>
                <Flag fontSize={'36px'} color={'#b98873'}></Flag>
            </span>
        </div>
    )
}

const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor('image', {
        cell: info => {
            const item = info.getValue();
            if (info.table.getCoreRowModel().rows.length > 15) {
                return <div>{item.title}</div>
            }
            return <img src={item.productImage} alt={item.title}></img>
        },
        header: 'Image',
        maxSize: 150
    }),
    columnHelper.accessor('product', {
        cell: info => {
            return (
                <div className='Standard'>
                    {info.getValue()}
                </div>
            )
        },
        header: 'Product',
        maxSize: 150
    }),
    columnHelper.accessor('type', {
        cell: info => {
            return (
                <div className='Standard'>
                    {info.getValue()}
                </div>
            )
        },
        header: 'Type',
        maxSize: 150
    }),
    columnHelper.accessor('split_id', {
        cell: info => {
            return <SplitIdComponent info={info}></SplitIdComponent>
        },
        header: 'Split ID',
        maxSize: 300
    }),
    columnHelper.accessor('sku', {
        cell: info => {
            return (
                <div className='Standard'>
                    {info.getValue()}
                </div>
            )
        },
        header: 'SKU',
        maxSize: 150
    }),
    columnHelper.accessor('scanned', {
        cell: (info) => {
            return <ScannedBoxComponent info={info}></ScannedBoxComponent>
        },
        header: 'Scanned',
        maxSize: 75
    })
];

export const OrderList = (props) => {
    const {order} = props;

    const [showLineItemFlagModal, setShowLineItemFlagModal] = useAtom(showLineItemFlagModalAtom);
    const issueReasons = useAtomValue(lineItemFlagReasonsAtom);

    const [selectedIssue, setSelectedIssue] = React.useState(null);
    const currentUser = useAtomValue(currentUserAtom);
    const [showUnscanConfirmModal, setShowUnscanConfirmModal] = useAtom(showUnscanConfirmModalAtom);

    const setCurrentBin = useSetAtom(binValueAtom);
    const setCurrentData = useSetAtom(currentStoredData);
    const currentPrinter = useAtomValue(selectedPrinterAtom);
    const setScanalytics = useSetAtom(scanalyticsAtom);

    const showSnackbar = useSetAtom(showSnackbarAtom);
    const snackbarMessage = useSetAtom(snackbarMessageAtom);

    const getOrdersFormatted = () => {
        const orders = [];
        order.lineItems.forEach((item, index) => {
            orders.push(
                {
                    image: item,
                    product: item.title,
                    type: item.type + ', ' + item.material,
                    split_id: item.split_id,
                    sku: item.sku,
                    scanned: item.split_custom_attributes.includes('Scanned'),
                    customAttributes: item.split_custom_attributes
                }
            )
        });
        return orders;
    }

    const getColumns = () => {
        const wide = window.innerWidth > 1200;
        if (wide) {
            return columns;
        } else {
            return [
                columns[0],
                columns[3],
                columns[5]
            ]
        }
    }

    const submitIssue = async () => {
        const data = showLineItemFlagModal.data;
        setShowLineItemFlagModal({ data: null, value: false });
        const selected = selectedIssue || issueReasons[0];

        await ApiServer.submitIssue(data, selected, currentUser);
        snackbarMessage(`Issue Submitted. ${selected.action === 'DEBUG' ? 'Item will be reviewed' : 'Item has been placed in the print queue.'}`);
        showSnackbar(true);
    }

    const confirmUnscan = async () => {
        const data = showUnscanConfirmModal.data;
        setShowUnscanConfirmModal({ data: null, value: false });

        await ApiServer.triggerWebhook(data, setCurrentData, setCurrentBin, currentPrinter, currentUser.id, setScanalytics, true);
        snackbarMessage('Unscan Successful');
        showSnackbar(true);
    }

    return (
        <>
            <span className='TableWrapper'>
                <TableComponent columns={getColumns} data={getOrdersFormatted()}></TableComponent>
                <div>
                    {
                        showLineItemFlagModal.value ?
                        <Modal closeModal={() => setShowLineItemFlagModal({ data: null, value: false })}>
                            <div className='ModalContent'>
                                <span>Report Line Item Issue</span>
                                <hr></hr>
                                <span>Product: {showLineItemFlagModal.data.product}</span>
                                <span>Line ID: {showLineItemFlagModal.data.split_id}</span>
                                <Select 
                                    label="Issue Reason"
                                    onChange={(value) => {
                                        console.log(value);
                                        setSelectedIssue(value);
                                    }}
                                    options={issueReasons}
                                ></Select>
                                <hr></hr>
                                <Button
                                    title="Submit Issue"
                                    action={submitIssue}
                                ></Button>
                            </div>
                        </Modal> :
                        null
                    }
                    {
                       showUnscanConfirmModal.value ?
                       <Modal closeModal={() => setShowUnscanConfirmModal({ data: null, value: false })}>
                            <div className='ModalContent'>
                                <span>Please Confirm Unscanning Line Item:</span>
                                <hr></hr>
                                <span>{showUnscanConfirmModal.data}</span>
                                <hr></hr>
                                <Button
                                    title="Confirm Unscan"
                                    action={confirmUnscan}
                                ></Button>
                            </div>
                       </Modal> :
                       null
                    }
                </div>
            </span>
        </>
    )
}