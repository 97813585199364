import React from 'react';
import './Select.styles.css';

export const Select = (props) => {
    const { label, onChange, options, defaultValue } = props;

    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        if (defaultValue && options.length > 0) {
            const option = options.find((op) => op.key === defaultValue || op.value === defaultValue)?.value;
            handleChange({target: {value: option}});
        } else if (options.length > 0) {
            handleChange({target: {value: options[0].value}});
        }
    }, [defaultValue, options]);
 
    const handleChange = (e) => {
        setValue(e.target.value);
        onChange(options.find((item) => item.value.toString() === e.target.value.toString()));
    }

    return (
        <div className='SelectWraper'>
            <label className='SelectLabel'>{label}</label>
            {
                options.length > 0 ?
                <select 
                    className="Select"
                    value={value}
                    onChange={handleChange}
                >
                    {
                        options.map((option) => {
                            return (
                                <option 
                                    key={`${option.key}-${option.value}`}
                                    value={option.value}
                                >
                                    {option.key}
                                </option>
                            )
                        })
                    }
                </select> : null
            }
        </div>
    )
}