import React from 'react';
import './ProductEntry.styles.css';
import { ApiServer } from '../../services/api';
import { useAtom } from 'jotai';
import { productArtTypesAtom, productBrandsAtom } from './../../state';
import { Select } from '../../components/Select/Select.component';
import { Input } from '../../components/Input/Input.component';
import { Button } from '../../components/button/Button.component';

export const ProductEntry = () => {

    const [artTypes, setArtTypes] = useAtom(productArtTypesAtom);
    const [artTypeValues, setArtTypeValues] = React.useState([]);
    const [artTypeDescription, setArtTypeDescription] = React.useState('');
    
    const [brands, setBrands] = useAtom(productBrandsAtom);
    const [brandValues, setBrandValues] = React.useState([]);

    const [selectedArtType, setSelectedArtType] = React.useState(-1);
    const [selectedBrand, setSelectedBrand] = React.useState(-1);
    const [productId, setProductId] = React.useState('');
    const [pat19File, setPat19File] = React.useState('');

    const [disableButton, setDisableButton] = React.useState(false);

    React.useEffect(() => {
        ApiServer.getArtTypes().then((results) => {
            setArtTypes(results);
        });

        ApiServer.getBrands().then((results) => {
            setBrands(results);
        });
    }, []);

    React.useEffect(() => {
        const values = [];
        artTypes.forEach((value) => {
            values.push({
                key: value.type_name,
                value: value.id
            })
        });
        setArtTypeValues(values);
    }, [artTypes]);

    React.useEffect(() => {
        const values = [];
        brands.forEach((value) => {
            values.push({
                key: value.name,
                value: value.id
            })
        });
        setBrandValues(values);
    }, [brands]);

    React.useEffect(() => {
        setArtTypeDescription(getSelectedArtTypeDescription());
    }, [selectedArtType]);

    const getSelectedArtTypeDescription = () => {
        return artTypes.find((value) => value.id === selectedArtType)?.description || '';
    }

    const submitForm = async () => {
        const formData = new FormData();
        formData.append('pat_19_file', pat19File);
        formData.append('product_id', productId);
        formData.append('brand', brands.find((b) => b.id === selectedBrand).shopify_brand_name);
        formData.append('art_type', JSON.stringify(artTypes.find((b) => b.id === selectedArtType)));
        setDisableButton(true);
        await ApiServer.saveProduct(formData);
        setDisableButton(false);
    }

    return (
        <div className='MainComponent'>
            <div className='ContentWrapper'>
                <div className='ContentContainer'>
                    <h1>Create New Product</h1>
                    <div className='FormColumn'>
                        <div className='FormRow'>
                            <div className='FormColumn FitArea'>
                                <Select options={artTypeValues} label={"Select Product Art Type"} onChange={(option) => setSelectedArtType(option.value)}></Select>
                                {
                                    artTypeDescription !== '' ?
                                    <span className='ArtTypeDescription'>{artTypeDescription}</span> :
                                    null
                                }
                            </div>
                            <div className='FitArea'>
                                <Input label={"Store Product Id"} onChange={setProductId} defaultValue={''}></Input>
                            </div>
                        </div>
                        <div className='FormRow'>
                            <div className='FormColumn FitArea'>
                                <Select options={brandValues} label={"Select Product Brand"} onChange={(option) => setSelectedBrand(option.value)}></Select>
                            </div>
                            <div className='FitArea'>
                                <Input label={"Pat 19 File"} onChange={setPat19File} defaultValue={''} type={'file'}></Input>
                            </div>
                        </div>
                        <div style={{ marginTop: '40px', display: 'flex', alignSelf: 'self-end' }}>
                            <Button title={'Submit Product'} action={submitForm} disabled={disableButton}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}