import axios from 'axios';
import { env } from './config';

class Api {
    config = {};

    constructor(config) {
        this.config = config;
    }

    getPrinters = async () => {
        let printers;

        try {
            printers = (await axios({ url: this.config.getPrintersUrl, method: 'GET'})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get printers');
        }
        return printers;
    }

    getStoredData = async (userId) => {
        let storedData;

        try {
            storedData = (await axios({url: `${this.config.getStoredDataUrl}?userId=${userId}`, method: 'GET'})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        if (storedData.length > 0 && Object.keys(storedData[0]).length > 0) {
            return storedData;
        } else {
            return [];
        }
    }

    triggerWebhook = async (barcodeValue, setCurrentData, setCurrentBin, selectedPrinter, userId, setScanalytics, isUnscan = false, isTest = false) => {
        if (barcodeValue.length === 0) return;
        if (barcodeValue[0] === '*') {
            barcodeValue = barcodeValue.substring(1);
        }
        
        const storedData = await this.getStoredData(userId);


        const splitCode = barcodeValue.split('-');
        if (splitCode.length > 1 && storedData.length > 0 && storedData[0].lineItems.length > 0) {
            const d = JSON.parse(JSON.stringify(storedData));
            d[0].lineItems = d[0].lineItems.map((item) => {
                if (item.split_id === barcodeValue && !isUnscan) {
                    item.split_custom_attributes += ' | Scanned';
                } else if (item.split_id === barcodeValue && isUnscan) {
                    item.split_custom_attributes = item.split_custom_attributes.split(' | ').filter(item => item !== 'Scanned').join(' | ');
                }
                return item;
            })
            setCurrentData(d);
        } else if (storedData.length > 0 && storedData[0].lineItems.length > 0) {
            const bin = barcodeValue.replace(/#/g, '');
            setCurrentBin(bin)
        }
        // check to see if barcode value is in the storedData already and set it to scanned or update bin respectively
        
        let webhookResponse;

        try {
            webhookResponse = (await axios({
                url: this.config.webhookUrl + (isTest ? '-test' : ''), 
                method: 'POST',
                data: {
                    splitId: barcodeValue,
                    storedData: storedData.length > 0 ? JSON.stringify(storedData[0]) : JSON.stringify({}),
                    userId: userId,
                    isUnscan
                }
            })).data;
            
            setCurrentData([webhookResponse]);

            this.storeDataOnServer(webhookResponse, userId);
            
            let allScanned = true;
            if (webhookResponse.lineItems) {
                const splits = webhookResponse.lineItems;
                for (let i = 0; i < splits.length; i++) {
                    if (!splits[i].split_custom_attributes.includes('Scanned')) {
                        allScanned = false;
                        break;
                    }
                }
                if (allScanned) {
                    this.performPrinting([webhookResponse], selectedPrinter);
                }
    
                if (webhookResponse && webhookResponse.BIN) {
                    setCurrentBin(webhookResponse.BIN);
                } else {
                    setCurrentBin('');
                }
    
                // setScanalytics(await this.getScanalytics());
            }

        } catch (e) {
            console.error(e);
        }
    }

    performPrinting = (data, selectedPrinter) => {
        if (data && data.length > 0 && data[0].lineItems && data[0].lineItems.length > 0) {
            const order = data[0];
            const zpl = ApiServer.toBase64(order.zpl);
            ApiServer.printZPL(zpl, order.order.orderNumber, selectedPrinter.printNodeId, selectedPrinter.stationName)
        }
    }

    getOrderForManualSearch = async (orderId, brand, setCurrentData, setCurrentBin) => {
        const webhookResponse = (await axios({
            url: this.config.getManualOrder + `?brand=${brand}&orderId=${orderId}`, 
            method: 'GET',
        })).data;
        
        setCurrentData([webhookResponse]);

        if (webhookResponse.lineItems) {
            if (webhookResponse && webhookResponse.BIN) {
                setCurrentBin(webhookResponse.BIN);
            } else {
                setCurrentBin('');
            }
        }
    }

    storeDataOnServer = async (data, userId) => {
        try {
            await axios({ 
                url: this.config.setStoredDataUrl, 
                method: 'POST', 
                headers: {
                    "Content-Type": "application/json"
                }, 
                data: {
                    data: JSON.stringify(data),
                    userId
                }
            });
        } catch (e) {
            console.error(e);
            throw new Error('Could Not Store Data on Server');
        }
    }

    toBase64 = (value) => {
        return btoa(unescape(encodeURIComponent(value)));
    }

    printZPL = async (zplString, orderId = '', printerId = '', selectedUser = '') => {
        const printNodeAPIKey = "1hcOF0731gdfA9wZIkOLYIRqCBSJm5wW_7fQDQw_Pb0";
        const idempotencyKey = orderId === ''
            ? this.generateIdempotencyKey()
            : orderId;
        const source = "FROM SCAN";
        
        try {
            await axios({
                url: this.config.printNode,
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "X-Idempotency-Key": idempotencyKey,
                    "Authorization": "Basic " + btoa(printNodeAPIKey + ":"),
                },
                data: JSON.stringify({
                    printerId,
                    title: "Packing Slip",
                    contentType: "raw_base64",
                    content: zplString,
                    source,
                    user: selectedUser,
                    expireAfter: 600,
                    options: {copies: 1, pages: 1}
                })
            });
        } catch (e) {
            if (e.response?.data?.code !== 'Conflict') {
                console.error(e);
                throw new Error('printing error');
            }
        }
    }

    generateIdempotencyKey = () => {
        return "key-" + Date.now();
    }

    sendWebhookForOrderId = async (orderId) => {
        const url = `${this.config.n8nWebhookUrl}?OrderID=${orderId}`;
        try {
            await axios({ url, method: 'GET' });
        } catch (e) {
            console.error(e);
            throw new Error('Failed to get n8n response');
        }
    }

    loginUser = async (username, password) => {
        let userData;

        try {
            userData = (await axios({url: this.config.userUrl, method: 'POST', data: {
                username,
                password
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return userData;
    }

    getInventoryItem = async (barcode) => {
        let barcodeData;

        try {
            barcodeData = (await axios({url: this.config.inventoryItemUrl, method: 'POST', data: {
                barcode
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return barcodeData;
    }

    changeInventoryItem = async (count, id, method) => {
        let updateResult;

        try {
            updateResult = (await axios({url: this.config.changeInventoryItem, method: 'POST', data: {
                count, id, method
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return updateResult;
    }

    addInventoryItem = async (name, barcode) => {
        let addResult;

        try {
            addResult = (await axios({url: this.config.addInventoryItem, method: 'POST', data: {
                name, barcode
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return addResult;
    } 

    updateInventoryItem = async (item) => {
        let updateData;

        try {
            updateData = (await axios({url: this.config.updateInventoryItem, method: 'POST', data: { item }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return updateData;
    }

    getInventoryProductTypes = async () => {
        let productTypeData;

        try {
            productTypeData = (await axios({url: this.config.productTypesUrl, method: 'POST', data: {}})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return productTypeData;
    }

    getInventoryItems = async (pageSize, page) => {
        let inventoryItems;

        try {
            inventoryItems = (await axios({url: this.config.inventoryItemsUrl, method: 'POST', data: {
                pageSize, page
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return inventoryItems;
    }

    getFeatureFlags = async (isProduction) => {
        let featureFlags;

        try {
            featureFlags = (await axios({url: this.config.featureFlagsUrl, method: 'POST', data: {
                production: isProduction,
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return featureFlags;
    }

    submitIssue = async (lineItem, issueFlag, user) => {
        let issueSubmitResponse;

        try {
            issueSubmitResponse = (await axios({url: this.config.submitIssueUrl, method: 'POST', data: {
                lineItem, issueFlag, user
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return issueSubmitResponse;
    }

    getScanalytics = async () => {
        let scanalytics;

        try {
            scanalytics = (await axios({url: this.config.scanalyticsUrl, method: 'GET'})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return scanalytics;
    }

    getIssueReasons = async () => {
        let issueReasons;

        try {
            issueReasons = (await axios({url: this.config.getIssueReasonsUrl, method: 'GET'})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return issueReasons;
    }

    getArtTypes = async () => {
        let artTypes;

        try {
            artTypes = (await axios({url: this.config.getArtTypes, method: 'GET'})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return artTypes;
    }

    getBrands = async () => {
        let brands;

        try {
            brands = (await axios({url: this.config.getBrands, method: 'GET'})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return brands;
    }

    saveProduct = async (form) => {
        let createProduct;

        try {
            createProduct = (await axios({url: this.config.submitProduct, method: 'POST', data: form, headers: {
                "Content-Type": "multipart/form-data"
            }})).data;
        } catch (e) {
            console.error(e);
            throw new Error('failed to get data');
        }
        return createProduct;
    }
}

export const ApiServer = new Api(env());