import React from 'react';
import './Inventory.styles.css';
import { atom, useSetAtom } from "jotai";
import { allowedKeys } from './../../services/utils';
import { ApiServer } from '../../services/api';
import { InventoryItem } from '../../components/inventory-item/InventoryItem.component';
import { NewInventoryItem } from '../../components/new-inventory-item/NewInventoryItem.component';
import { ListInventory } from '../../components/list-inventory/ListInventory.component';

export const currentInventoryItemScanned = atom(null);

export const InventoryPage = () => {

    const [pageState, _setPageState] = React.useState('scanner');
    const pageStateRef = React.useRef('scanner');
    const setPageState = (value) => {
        pageStateRef.current = value;
        _setPageState(value);
    }

    const [scanValue, _setScanValue] = React.useState('');
    const currentScanValue = React.useRef('');
    const setScanValue = (value) => {
        currentScanValue.current = value;
        _setScanValue(value);
    }

    const [scanned, _setHasScanned] = React.useState(false);
    const hasScanned = React.useRef(false);
    const setHasScanned = (value) => {
        hasScanned.current = value;
        _setHasScanned(value);
    }
    const [invalidScan, _setInvalidScan] = React.useState(false);
    const hasInvalidScan = React.useRef(false);
    const setHasInvalidScan = (value) => {
        hasInvalidScan.current = value;
        _setInvalidScan(value);
    }

    const [, _setIsModalOpen] = React.useState(false);
    const isModalOpen = React.useRef(false);
    const setIsModalOpen = (value) => {
        _setIsModalOpen(value);
        isModalOpen.current = value;
    }

    const setInventoryItemScanned = useSetAtom(currentInventoryItemScanned);
    

    React.useEffect(() => {
        document.addEventListener('keypress', keyListener);
        return () => {
            document.removeEventListener('keypress', keyListener);
        }
    }, []);

    const keyListener = (e) => {
        if (pageStateRef.current !== 'scanner') return;

        if (e.key !== 'Enter' && allowedKeys.includes(e.key)) {
            if (!hasScanned.current) {
                setHasScanned(false);
                if (e.key !== 'Shift' && e.key !== 'Control' && e.key !== 'Alt' && e.key !== 'Backspace') {
                    setScanValue(currentScanValue.current ? currentScanValue.current + e.key : '' + e.key);
                }
                if (e.key === 'Backspace') {
                    setScanValue(currentScanValue.current.slice(0, currentScanValue.current.length - 2));
                }
            }
        } else if (e.key === 'Enter') {
            let textValue = currentScanValue.current;
            ApiServer.getInventoryItem(textValue).then((value) => {
                if (value) {
                    setScanValue('');
                    setHasInvalidScan(false);
                    setHasScanned(true);
                    setInventoryItemScanned(value);
                } else {
                    setInventoryItemScanned(null);
                    setHasInvalidScan(true);
                    setHasScanned(false);
                }
            });
        }
    }

    const onSave = async (name, barcode) => {
        const savedScan = await ApiServer.addInventoryItem(name, barcode);
        if (savedScan) {
            setScanValue('');
            setHasInvalidScan(false);
            setHasScanned(true);
            setInventoryItemScanned(savedScan);
        } else {
            setScanValue('');
            console.error('Inventory Item Already Exists');
            setHasScanned(false);
            setHasInvalidScan(false);
        }
    }

    const setOpenModal = (value) => {
        setIsModalOpen(value);
    }

    const clickHeaderItem = (value) => {
        setPageState(value);
        setHasScanned(false);
        setHasInvalidScan(false);
        setScanValue('');
    }

    return (
        <div className='InventoryWrapper'>
            <div className='InventoryPagesHeaders' style={{ borderBottom: '1px solid black', height: '35px' }}>
                <div className='InventoryHeaderWrapper'>
                    <div className='HeaderItem' onClick={() => clickHeaderItem('scanner')}>Scanner</div>
                    <div className='HeaderItem' onClick={() => clickHeaderItem('all-items')}>All Items</div>
                </div>
            </div>
            {
                pageState === 'scanner' ?
                <div className="InventoryPagesHeaders">
                    {
                        scanned ?
                        <InventoryItem emitOpenModal={setOpenModal}></InventoryItem> :
                        (
                            invalidScan ?
                            <NewInventoryItem barcodeValue={scanValue} saveItem={onSave}></NewInventoryItem> :
                            <span style={{ fontSize: '32px' }}>Please Scan An Item To Track Its Inventory</span>
                        )
                    }
                </div> : null
            }
            {
                pageState === 'all-items' ?
                <ListInventory>

                </ListInventory> : null
            }
        </div>
    )
}