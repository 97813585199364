export const navItems = [
    {
        name: 'Scanner',
        path: '/'
    },
    {
        name: 'Analytics',
        path: '/analytics',
        flag: 'bs-scanalytic-page'
    },
    {
        name: 'Inventory',
        path: '/inventory',
        flag: 'bs-inventory-page'
    },
    {
        name: 'Product Entry',
        path: '/product-entry',
        flag: 'product-entry'
    }
];