import React from 'react';
import './Navigation.styles.css';
import { 
    currentUserAtom, 
    featureFlagsAtom, 
    scanalyticsWindowAtom,
    currentStoredData,
    binValueAtom
} from './../../state';
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ArrowLeft } from './../../images/arrow-left';
import { navItems } from './Navigation.routes';
import { NavItem } from './components/NavItem/NavItem.component';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiServer } from '../../services/api';
import { Button } from '../button/Button.component';

export const Navigation = (props) => {
    
    const setCurrentUser = useSetAtom(currentUserAtom);
    const [scanalyticWindow, setScanalyticWindow] = useAtom(scanalyticsWindowAtom);
    const navigate = useNavigate();
    const location = useLocation();

    const [manualSearchValue, setManualSearchValue] = React.useState('');
    const setCurrentData = useSetAtom(currentStoredData);
    const setCurrentBin = useSetAtom(binValueAtom);

    const flags = useAtomValue(featureFlagsAtom);

    const closeSidebar = () => {
        setScanalyticWindow(!scanalyticWindow);
    }

    const logoutUser = () => {
        setCurrentUser(null);
        localStorage.removeItem('user');
        closeSidebar();
    }

    const navigateToPath = (path) => {
        navigate(path);
        closeSidebar();
    }

    const onValueChange = (e) => {
        const value = e.target.value;
        setManualSearchValue(value);
    }

    const submitSearch = (brand) => {
        ApiServer.getOrderForManualSearch(manualSearchValue, brand, setCurrentData, setCurrentBin);
        setManualSearchValue('');
        closeSidebar();
    }

    return (
        <div className='NavigationWrapper'>
            <div className='CloseWrapper' onClick={closeSidebar}>
                <span><ArrowLeft fontSize='18px'></ArrowLeft></span> Close
            </div>
            <span style={{borderTop: '1px solid black'}}>
                {
                    navItems.map((item) => {
                        if (!item.flag || (item.flag && flags[item.flag])) {
                            return <span key={`navItem-${item.path}`}>
                                <NavItem navName={item.name} isFocused={location.pathname === item.path} onclick={() => navigateToPath(item.path)}></NavItem>
                            </span>
                        } else return null;
                    })
                }
            </span>
            <NavItem navName={'Logout'} onclick={logoutUser}></NavItem>
                
            <div className='ScanCountArea'>
                <input type="text" placeholder='Manually Search Order' onChange={onValueChange}/>
                <Button title="Search WB" action={() => submitSearch('WB')} ></Button>
                <Button title="Search PB" action={() => submitSearch('PB')} ></Button>
            </div>
        </div>
    )
}