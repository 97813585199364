import React from 'react';
import './InventoryItem.styles.css';
import { useAtom, useSetAtom } from 'jotai';
import { currentInventoryItemScanned } from '../../pages/inventory/Inventory.component';
import { Button } from './../button/Button.component';
import { ApiServer } from '../../services/api';
import { Modal } from '../modal/Modal.component';
import { Input } from '../Input/Input.component';
import { Select } from '../Select/Select.component';
import { inventoryProductTypesAtom } from './../../state/index';
import { Snackbar, showSnackbarAtom, snackbarMessageAtom } from '../snackbar/Snackbar.component';

export const InventoryItem = (props) => {

    const { emitOpenModal } = props;

    const [scannedItem, setScannedItem] = useAtom(currentInventoryItemScanned);
    const [showModal, setShowModal] = React.useState(false);
    const [method, setMethod] = React.useState('add');

    const [inputAmount, setInputAmount] = React.useState('');

    const [errorMessage, setErrorMessage] = React.useState('');

    const [productTypes, setProductTypes] = useAtom(inventoryProductTypesAtom);

    const setSnackbarMessage = useSetAtom(snackbarMessageAtom);
    const setShowSnackbar = useSetAtom(showSnackbarAtom);

    React.useEffect(() => {
        if (!productTypes) {
            ApiServer.getInventoryProductTypes().then((values) => {
                setProductTypes(values);
            });
        }
    }, [productTypes]);

    const addInventory = () => {
        setShowModal(true);
        setMethod('add');
        emitOpenModal(true);
    }

    const removeInventory = () => {
        setShowModal(true);
        setMethod('sub');
        emitOpenModal(true);
    }

    const decrease = async () => {
        const scan = await ApiServer.changeInventoryItem(1, scannedItem.id, 'decrease');
        setScannedItem(scan);
    }

    const changeInventory = async () => {
        if (method === 'add') {
            const scan = await ApiServer.changeInventoryItem(inputAmount, scannedItem.id, 'increase');
            setScannedItem(scan);
            setShowModal(false);
            emitOpenModal(false);
            setInputAmount('');
            setErrorMessage('');
        } else if (method === 'sub') {
            const scan = await ApiServer.changeInventoryItem(inputAmount, scannedItem.id, 'decrease');
            setScannedItem(scan);
            setShowModal(false);
            emitOpenModal(false);
            setInputAmount('');
            setErrorMessage('');
        }
    }

    const onInputChange = (e) => {
        if (!isNaN(e.target.value) && !isNaN(parseInt(e.target.value))) {
            setInputAmount(e.target.value);
            setErrorMessage('');
        } else {
            setErrorMessage('Input Value Is Not A Number');
        }
    }

    const onDetailChange = (value, key) => {
        setScannedItem(Object.assign(scannedItem, { [key]: value }));
    }

    const onUpdateItem = async () => {
        const update = await ApiServer.updateInventoryItem(scannedItem);
        if (update) {
            setScannedItem(update);
            setSnackbarMessage('Updated The Inventory Item Successfully');
            setShowSnackbar(true);
        } else {
            setSnackbarMessage('Failed To Update The Item. Please Check the values entered.');
            setShowSnackbar(true);
        }
    }

    return (
        <div className='InventoryItemWrapper'>
            <div className='InventoryItem'>
                <div className="Image">
                    <img src={scannedItem?.image} alt={scannedItem?.name} />
                </div>
                <div className="Information">
                    <div className="InfoItem">
                        <strong>Name:</strong>
                        <span>{scannedItem?.name}</span>
                    </div>
                    <div className="InfoItem">
                        <strong>Item Id:</strong>
                        <span>{scannedItem?.id}</span>
                    </div>
                    <div className="InfoItem">
                        <strong>Inventory Count:</strong>
                        <span>{scannedItem?.inventory}</span>
                    </div>
                </div>
                <div className='InventoryActions'>
                    <Button title="Add Inventory" action={addInventory}></Button>
                    <Button title="Remove Inventory" action={removeInventory}></Button>
                    <Button title="Decrease By One" action={decrease}></Button>
                </div>
            </div>
            <div className='AdditionalDetails'>
                <div className='DetailsSection'>
                    <Input 
                        label="Item Name" 
                        onChange={(value) => onDetailChange(value, 'name')} 
                        defaultValue={scannedItem.name}>
                    </Input>
                    <Input 
                        label="Barcode" 
                        onChange={(value) => onDetailChange(value, 'barcode')} 
                        defaultValue={scannedItem.barcode}>
                    </Input>
                    <Input 
                        label="Notes"
                        onChange={(value) => onDetailChange(value, 'location')} 
                        defaultValue={scannedItem.location}>
                    </Input>
                </div>
                <div className='DetailsSection'>
                    <Input 
                        label="Item Weight" 
                        onChange={(value) => onDetailChange(value, 'weight')} 
                        defaultValue={scannedItem.weight}>
                    </Input>
                    <Input 
                        label="Notification Threshold"
                        onChange={(value) => onDetailChange(value, 'threshold')} 
                        defaultValue={scannedItem.threshold}>
                    </Input>
                    <Select 
                        label="Product Type" 
                        onChange={(value) => onDetailChange(value.value, 'productTypeId')} 
                        options={productTypes ? productTypes : []}
                        defaultValue={scannedItem.productType}>
                    </Select>
                </div>
                <div className='DetailsSection ButtonSection'>
                    <Button title="Update" action={onUpdateItem}></Button>
                </div>
            </div>
            {   
                showModal ?
                <Modal closeModal={() => setShowModal(false)}>
                    <div className='ModalContent'>
                        {
                            method === 'add' ?
                            <span>Add Inventory To {scannedItem.name}</span> :
                            <span>Remove Inventory From {scannedItem.name}</span>
                        }
                        <input 
                            defaultValue={inputAmount} 
                            placeholder={method === 'add' ? 'Add Inventory' : 'Remove Inventory'} 
                            onChange={onInputChange} 
                        />
                        <Button title="Save Changes" action={changeInventory} ></Button>
                        {
                            errorMessage !== '' ?
                            <span style={{color: 'red'}}>{errorMessage}</span> :
                            null
                        }
                    </div>
                </Modal> :
                null
            }
            <Snackbar></Snackbar>
        </div>
    )
}