import React from 'react';
import "./OrderDisplay.styles.css";
import { useAtomValue } from 'jotai';
import { currentStoredData } from './../../state';
import { OrderList } from '../order-list/OrderList.component';
import fulfilled from "./../../images/Fulfilled.png";

export const OrderDisplay = () => {
    const data = useAtomValue(currentStoredData);

    if (data.length <= 0) {
        return null;
    } else if (!data[0].order) {
        return null;
    }

    const order = () => {
        return data[0];
    }

    const getDate = () => {
        let dateObj = new Date(order().order.createdAt);
        return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
    }

    const getLineItemCount = () => {
        return order().lineItems.length;
    }

    const getScannedLineItemCount = () => {
        let count = 0;
        order().lineItems.forEach((item) => {
            if (item.split_custom_attributes.includes('Scanned')) count++;
        });
        return count;
    }

    const getIsAllScanned = () => {
        let count = 0;
        order().lineItems.forEach((item) => {
            if (item.split_custom_attributes.includes('Scanned')) count++;
        });
        return count === order().lineItems.length;
    }

    return (
        <div className="OrderDisplayWrapper">
            <div className="OrderDisplayContainer">
                <div className="OrderDisplayTopSection">
                    <div className='OrderDisplayTopLeft'>
                        <div className='OrderNumber'>Order #: {order().order.orderNumber}</div>
                        <div className='OrderDate'>Date: {getDate()}</div>
                        <div className='OrderStatus'>Status: {order().order.financialStatus} - {order().order.fulfillmentStatus}</div>
                    </div>
                    <div className="OrderDisplayTopCenter">
                        {   getIsAllScanned() ?
                            <img src={fulfilled}></img> :
                            null
                        }
                    </div>
                    <div className="OrderDisplayTopRight">
                        <span>{getScannedLineItemCount()} / {getLineItemCount()} Scanned</span>
                    </div>
                </div>
                <div className='OrderList'>
                    <OrderList
                        order={order()}
                    ></OrderList>
                </div>
            </div>
        </div>
    )
}