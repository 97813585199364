

export const env = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return {
            production: false,
            webhookUrl: "http://localhost:3000/webhook-request",
            n8nWebhookUrl: "https://wallblush.app.n8n.cloud/webhook-test/af8ae1ee-b5eb-4e7a-b43a-70dec43e2efe",
            getManualOrder: "https://automation.wallblush.com:5678/webhook/manual-search",
            getStoredDataUrl: "http://localhost:3000/get-stored-data",
            setStoredDataUrl: "http://localhost:3000/store-data",
            printNode: 'https://api.printnode.com/printjobs',
            getPrintersUrl: 'http://localhost:3000/get-printers',
            userUrl: 'http://localhost:3000/get-user',
            featureFlagsUrl: 'http://localhost:3000/get-feature-flags',
            scanalyticsUrl: 'http://localhost:3000/get-scanalytics',
            inventoryItemUrl: 'http://localhost:3000/inventory/get-inventory-item',
            changeInventoryItem: 'http://localhost:3000/inventory/change-inventory-item',
            addInventoryItem: 'http://localhost:3000/inventory/add-inventory-item',
            productTypesUrl: 'http://localhost:3000/inventory/get-inventory-product-types',
            inventoryItemsUrl: 'http://localhost:3000/inventory/get-inventory-items',
            updateInventoryItem: 'http://localhost:3000/inventory/update-inventory-item',
            getIssueReasonsUrl: 'http://localhost:3000/line-item/get-issue-reasons',
            submitIssueUrl: 'http://localhost:3000/line-item/submit-issue',
            getArtTypes: 'http://localhost:3000/product-data/art-types',
            getBrands: 'http://localhost:3000/product-data/brands',
            submitProduct: 'http://localhost:3100/products/create-product'
        }
    } else if (process.env.REACT_APP_ENVIRONMENT === 'test') {
        // rm -rf barcode-scanner/ && git clone git@github.com:Wall-blush/barcode-scanner.git && cd barcode-scanner/ && npm install && npm run build:test && cd public && npm install && npm run build:test && cd ../../ && pm2 restart server && pm2 status
        return {
            production: false,
            webhookUrl: "https://scanner-test.wallblush.com/webhook-request",
            n8nWebhookUrl: "https://wallblush.app.n8n.cloud/webhook-test/af8ae1ee-b5eb-4e7a-b43a-70dec43e2efe",
            getManualOrder: "https://automation.wallblush.com:5678/webhook/manual-search",
            getStoredDataUrl: "https://scanner-test.wallblush.com/get-stored-data",
            setStoredDataUrl: "https://scanner-test.wallblush.com/store-data",
            printNode: 'https://api.printnode.com/printjobs',
            getPrintersUrl: 'https://scanner-test.wallblush.com/get-printers',
            userUrl: 'https://scanner-test.wallblush.com/get-user',
            featureFlagsUrl: 'https://scanner-test.wallblush.com/get-feature-flags',
            scanalyticsUrl: 'https://scanner-test.wallblush.com/get-scanalytics',
            inventoryItemUrl: 'https://scanner-test.wallblush.com/inventory/get-inventory-item',
            changeInventoryItem: 'https://scanner-test.wallblush.com/inventory/change-inventory-item',
            addInventoryItem: 'https://scanner-test.wallblush.com/inventory/add-inventory-item',
            productTypesUrl: 'https://scanner-test.wallblush.com/inventory/get-inventory-product-types',
            inventoryItemsUrl: 'https://scanner-test.wallblush.com/inventory/get-inventory-items',
            updateInventoryItem: 'https://scanner-test.wallblush.com/inventory/update-inventory-item',
            getIssueReasonsUrl: 'https://scanner-test.wallblush.com/line-item/get-issue-reasons',
            submitIssueUrl: 'https://scanner-test.wallblush.com/line-item/submit-issue',
            getArtTypes: 'https://scanner-test.wallblush.com/product-data/art-types',
            getBrands: 'https://scanner-test.wallblush.com/product-data/brands',
            submitProduct: 'https://scanner-test.wallblush.com/products/create-product'
        }
    } else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        // rm -rf barcode-scanner/ && git clone git@github.com:Wall-blush/barcode-scanner.git && cd barcode-scanner/ && npm install && npm run build && cd public && npm install && npm run build && cd ../../ && pm2 restart server && pm2 status
        return {
            production: true,
            webhookUrl: "https://scanner.wallblush.com/webhook-request",
            n8nWebhookUrl: "https://wallblush.app.n8n.cloud/webhook-test/af8ae1ee-b5eb-4e7a-b43a-70dec43e2efe",
            getManualOrder: "https://automation.wallblush.com:5678/webhook/manual-search",
            getStoredDataUrl: "https://scanner.wallblush.com/get-stored-data",
            setStoredDataUrl: "https://scanner.wallblush.com/store-data",
            printNode: 'https://api.printnode.com/printjobs',
            getPrintersUrl: 'https://scanner.wallblush.com/get-printers',
            userUrl: 'https://scanner.wallblush.com/get-user',
            featureFlagsUrl: 'https://scanner.wallblush.com/get-feature-flags',
            scanalyticsUrl: 'https://scanner.wallblush.com/get-scanalytics',
            inventoryItemUrl: 'https://scanner.wallblush.com/inventory/get-inventory-item',
            changeInventoryItem: 'https://scanner.wallblush.com/inventory/change-inventory-item',
            addInventoryItem: 'https://scanner.wallblush.com/inventory/add-inventory-item',
            productTypesUrl: 'https://scanner.wallblush.com/inventory/get-inventory-product-types',
            inventoryItemsUrl: 'https://scanner.wallblush.com/inventory/get-inventory-items',
            updateInventoryItem: 'https://scanner.wallblush.com/inventory/update-inventory-item',
            getIssueReasonsUrl: 'https://scanner.wallblush.com/line-item/get-issue-reasons',
            submitIssueUrl: 'https://scanner.wallblush.com/line-item/submit-issue',
            getArtTypes: 'https://scanner.wallblush.com/product-data/art-types',
            getBrands: 'https://scanner.wallblush.com/product-data/brands',
            submitProduct: 'https://scanner.wallblush.com/products/create-product'
        }
    }
}


/**
 * 
 * export N8N_HOST=automation.wallblush.com && export N8N_PORT=5678 && export N8N_PROTOCOL=https && export WEBHOOK_TUNNEL_URL=https://automation.wallblush.com/ && export N8N_SSL_KEY=/etc/letsencrypt/live/automation.wallblush.com/privkey.pem && export N8N_SSL_CERT=/etc/letsencrypt/live/automation.wallblush.com/fullchain.pem && n8n start
 */