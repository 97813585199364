import React from 'react';
import "./Table.component.css";
import {
    flexRender,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table';

export const TableComponent = (props) => {

    const { columns, data } = props;
    const table = useReactTable({
        data,
        columns: columns(),
        getCoreRowModel: getCoreRowModel(),
        defaultColumn: {
            minSize: 0,
            size: Number.MAX_SAFE_INTEGER,
            maxSize: Number.MAX_SAFE_INTEGER
        }
    });

    return (
        <>
            <table>
                <thead>
                    {table.getHeaderGroups().map((group) => (
                        <tr key={group.id}>
                            {group.headers.map((header) => (
                                <th 
                                    key={header.id}
                                    style={{
                                        width: header.getSize() === Number.MAX_SAFE_INTEGER ? "200px" : header.getSize()
                                    }}
                                >
                                    <span>
                                        {header.isPlaceholder 
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                        <tr key={row.id} className={row.original['scanned'] ? 'Scanned' : ''}>
                            {row.getVisibleCells().map((cell) => (
                                <td 
                                    key={cell.id}
                                    style={{
                                        width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? "200px" : cell.column.getSize()
                                    }}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    )})}
                </tbody>
            </table>
        </>
    )
}