import React from 'react';
import "./Main.styles.css";
import { HeaderComponent } from "../../components/header/Header.component";
import { useSetAtom, useAtom } from "jotai";
import { currentStoredData, currentUserAtom, printersAtom, selectedPrinterAtom } from "../../state";
import { OrderDisplay } from '../../components/order-display/OrderDisplay.component';
import { ApiServer } from '../../services/api';
import { Snackbar } from '../../components/snackbar/Snackbar.component';

export const MainComponent = (props) => {
    
    const setPrinters = useSetAtom(printersAtom)
    const [selectedPrinter, setSelectedPrinter] = useAtom(selectedPrinterAtom);
    const setCurrentData = useSetAtom(currentStoredData);

    const [, _setSelectedPrintLocal] = React.useState(null);
    const selectedPrinterRef = React.createRef(null);
    const setSelectedPrinterLocal = (value) => {
        _setSelectedPrintLocal(value);
        selectedPrinterRef.current = value;
    }

    const [currentUser, setCurrentUser] = useAtom(currentUserAtom);

    React.useEffect(() => {
        setSelectedPrinterLocal(selectedPrinter);
    }, [selectedPrinter])

    React.useEffect(() => {
        if (currentUser) {
            setCurrentData(ApiServer.getStoredData(currentUser.id));
            ApiServer.getPrinters().then((printers) => {
                if (printers.length > 0) {
                    setSelectedPrinter(printers[0]);
                    setPrinters(printers);
                    setSelectedPrinterLocal(printers[0]);
                }
            });
        }
    }, [currentUser]);

    return (
        <div className="MainComponent">
            <div className="ContentWrapper">
                <div className='ContentContainer'>
                    <HeaderComponent></HeaderComponent>
                    <OrderDisplay></OrderDisplay>
                </div>
            </div>
            <Snackbar></Snackbar>
        </div>
    )
}