import React from 'react';
import './NavItem.styles.css';

export const NavItem = (props) => {

    const { navName, onclick, isFocused } = props;

    return (
        <div className={`NavItemWrapper ${isFocused ? 'IsFocused' : ''}`} onClick={onclick}>
            {navName}
        </div>
    )
}