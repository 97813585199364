import React from 'react';
import './Button.styles.css';

export const Button = (props) => {
    const { title, action, disabled = false } = props;
    return (
        <button className='Button' onClick={action} disabled={disabled}>
            { title || 'Submit' }
        </button>
    )
}