import React from 'react';
import './Analytics.styles.css';

export const AnalyticsPage = () => {

    return (
        <div className="AnalyticsWrapper">
            Analytics Page Is Under Construction
        </div>
    )
}