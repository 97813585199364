import React from 'react';
import './Input.styles.css';

export const Input = (props) => {
    const { label, onChange, defaultValue, type = 'text' } = props;

    const inputRef = React.useRef(null);

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = defaultValue;
        }
    }, [defaultValue]);

    return (
        <div className='InputWrapper'>
            <label>{label}</label>
            <input 
                ref={inputRef}
                type={type}
                onChange={(e) => {
                    e.stopPropagation();
                    if (type === 'file') {
                        onChange(e.target.files[0])
                    } else {
                        onChange(e.target.value)
                    }
                }}
            />
        </div>
    )
}