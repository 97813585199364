import React from 'react';
import './Modal.styles.css';

export const Modal = (props) => {

    const { closeModal, children } = props;

    return (
        <div className="ModalWrapper" onClick={closeModal}>
            <div className="ModalContainer" onClick={(e) => e.stopPropagation()}>
                { children }
            </div>
        </div>
    )
}