import React from 'react';
import './Login.styles.css';
import { ApiServer } from '../../services/api';
import { useSetAtom } from 'jotai';
import { currentUserAtom } from '../../state';
import { Button } from './../../components/button/Button.component';
import { useNavigate } from 'react-router-dom';
import { Input } from './../../components/Input/Input.component';


export const LoginComponent = () => {

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [invalidUser, setInvalidUser] = React.useState(false);

    const setCurrentUser = useSetAtom(currentUserAtom);

    const router = useNavigate();

    const usernameChange = (value) => {
        setUsername(value);
    }

    const passwordChange = (value) => {
        setPassword(value);
    }

    const submitUser = async () => {
        const userData = await ApiServer.loginUser(username, password);
        if (userData) {
            setCurrentUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
            router('/');
        } else {
            setInvalidUser(true);
        }
    }

    const buttonDisabled = () => {
        return username === '' || password === '';
    }

    return (
        <div className='LoginWrapper'>
            <div className='LoginBubble'>
                <Input label="Username" defaultValue={username} onChange={usernameChange} type="text"></Input>
                <Input label="Password" defaultValue={password} onChange={passwordChange} type="password"></Input>
                <span style={{minHeight: '8px'}}></span>
                <Button title="Login" action={submitUser} disabled={buttonDisabled()} ></Button>
                {
                    invalidUser ? (
                        <span className='ErrorMessage'>Invalid Username or Password</span>
                    ) : null
                }
            </div>
        </div>
    )
}