import React from 'react';
import "./Header.styles.css"
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { binValueAtom, currentStoredData, currentUserAtom, inputAtom, printersAtom, scanalyticsAtom, selectedPrinterAtom } from '../../state';
import { ApiServer } from '../../services/api';
import { Button } from './../button/Button.component';
import { allowedKeys } from './../../services/utils';

export const HeaderComponent = () => {
    const bin = useAtomValue(binValueAtom);

    const [currentData, setCurrentData] = useAtom(currentStoredData);
    const setCurrentBin = useSetAtom(binValueAtom);
    const [currentPrinter, _setSelectedPrinter] = useAtom(selectedPrinterAtom);
    const currentPrinterRef = React.createRef(currentPrinter);
    const setSelectedPrinter = (value) => {
        _setSelectedPrinter(value);
        currentPrinterRef.current = value;
    }
    const printers = useAtomValue(printersAtom);
    
    const [inputValue, setInputValue] = useAtom(inputAtom);

    const [, _setInputValueLocal] = React.useState('');
    const inputValueRef = React.createRef('');
    const setInputValueLocal = (value) => {
        _setInputValueLocal(value);
        inputValueRef.current = value;
        setInputValue(value);
    }

    const [_inputBox, _setInputBox] = React.useState('');
    const inputBox = React.useRef(_inputBox);
    const setInputBox = (value) => {
        inputBox.current = value;
        _setInputBox(value);
    }

    const currentUser = useAtomValue(currentUserAtom);

    const setScanalytics = useSetAtom(scanalyticsAtom);

    React.useEffect(() => {
        document.addEventListener('keydown', handleScan);
        return () => {
            document.removeEventListener('keydown', handleScan);
        }
    }, [currentPrinter]);

    React.useEffect(() => {
        currentPrinterRef.current = currentPrinter;
    }, [currentPrinter]);

    React.useEffect(() => {
        const defaultPrinter = localStorage.getItem('defaultPrinter');
        if (defaultPrinter) {
            setSelectedPrinter(JSON.parse(defaultPrinter));
        }
    }, [printers]);

    const handleScan = (e) => {
        if (e.key !== 'Enter' && allowedKeys.includes(e.key)) {
            if (e.key !== 'Shift' && e.key !== 'Control' && e.key !== 'Alt' && e.key !== 'Backspace') {
                setInputValueLocal(inputValueRef.current ? inputValueRef.current + e.key : '' + e.key);
            }
            if (e.key === 'Backspace') {
                setInputValueLocal(inputValueRef.current.slice(0, inputValueRef.current.length - 2));
            }
        } else if (e.key === 'Enter') {
            setInputBox('');
            ApiServer.triggerWebhook(inputValueRef.current, setCurrentData, setCurrentBin, currentPrinterRef.current, currentUser.id, setScanalytics);
            setInputValueLocal('');
        }
    }

    const handleSelectPrinterChange = (e) => {
        const p = printers.find((printer) => printer.printNodeId.toString() === e.target.value);
        localStorage.setItem('defaultPrinter', JSON.stringify(p));
        setSelectedPrinter(p);
    }

    const getSelectValue = () => {
        if (currentPrinter && currentPrinter.printNodeId) {
            return currentPrinter.printNodeId
        } else {
            return '';
        }
    }

    const forcePrint = () => {
        const zpl = ApiServer.toBase64(currentData[0].zpl);
        ApiServer.printZPL(zpl, '', currentPrinter.printNodeId, currentPrinter.stationName);
    }

    return (
        <div className='HeaderWrapper'>
            <div className='HeaderInput'>
                <input 
                    type="text"
                    placeholder='Scan or enter barcode'
                    defaultValue={inputBox.current}
                />
            </div>

            {printers.length > 0 ?
                <div className='PrinterSelect BinDisplay'>
                    <span><strong>Printer:</strong> </span>
                    <select value={getSelectValue()} onChange={handleSelectPrinterChange}>
                        {printers.map((printer) => {
                            return (
                                <option key={printer.printNodeId} value={printer.printNodeId}>{printer.stationName}</option>
                            )
                        })}
                    </select>
                </div> : null
            }

            {bin !== '' ?
                <div className='BinDisplay bin'>
                    <strong>Bin:</strong> {bin.toUpperCase()}
                </div> : null
            }
            
            {currentData.length > 0 ?
                <div className='BinDisplay ForcePrint'>
                    <Button title="Force Print" action={forcePrint}>Force Print</Button>
                </div> : null
            }
        </div>
    )
}