import { atom } from 'jotai';
import { env } from '../services/config';

export const inputAtom = atom('');
export const binValueAtom = atom('');
export const selectedOrder = atom('');
export const selectedPrint = atom('');
export const selectedUser = atom('');

export const currentStoredData = atom([]);

export const printersAtom = atom([]);
export const selectedPrinterAtom = atom(null);

export const currentUserAtom = atom(null);

export const sidebarOpenAtom = atom(false);

export const featureFlagsAtom = atom({});

export const productionAtom = atom(env().production);

export const scanalyticsAtom = atom([]);

export const scanalyticsWindowAtom = atom(false);

export const inventoryProductTypesAtom = atom(null);
export const showLineItemFlagModalAtom = atom({ data: null, value: false });
export const lineItemFlagReasonsAtom = atom([]);

export const showUnscanConfirmModalAtom = atom({ data: null, value: false });

export const productBrandsAtom = atom([]);
export const productArtTypesAtom = atom([]);