import React from 'react';
import './Snackbar.styles.css';
import { atom, useAtom, useAtomValue } from 'jotai';

export const showSnackbarAtom = atom(false);
export const snackbarMessageAtom = atom('');

export const Snackbar = () => {

    const [showSnackbar, setShowSnackbar] = useAtom(showSnackbarAtom);
    const snackbarMessage = useAtomValue(snackbarMessageAtom);

    React.useEffect(() => {
        if (showSnackbar) {
            setTimeout(() => {
                setShowSnackbar(false);
            }, 5000);
        }
    }, [showSnackbar])

    return (
        <div className='SnackbarWrapper' style={{ top: showSnackbar ? '10px' : '-100px' }}>
            {snackbarMessage}
        </div>
    )
}