import React from 'react';
import './NewInventoryItem.styles.css';
import { Button } from '../button/Button.component';
import { Input } from '../Input/Input.component';

export const NewInventoryItem = (props) => {
    const { barcodeValue, saveItem } = props;

    const [name, setName] = React.useState('');
    const [barcode, setBarcode] = React.useState(barcodeValue);

    return (
        <div className="NewInventoryItemWrapper">
            
            <Input defaultValue={name} label="Item Name" onChange={(e) => setName(e.target.value)}></Input>
            <Input defaultValue={barcode} label="Barcode Value" onChange={(e) => setBarcode(e.target.value)}></Input>
            <Button title="Save Item" action={() => saveItem(name, barcode)}></Button>

        </div>
    )
}