import React from 'react';
import './Routes.styles.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { MainComponent } from '../../pages/main/Main.component';
import { currentUserAtom, featureFlagsAtom, scanalyticsAtom, scanalyticsWindowAtom, productionAtom, lineItemFlagReasonsAtom } from './../../state';
import logo from "./../../images/Wallblush_logo.webp";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ApiServer } from '../../services/api';
import { Hamburger } from "./../../images/hamburger";
import { Navigation } from './../navigation/Navigation.component';
import { AnalyticsPage } from '../../pages/analytics/Analytics.component';
import { LoginComponent } from '../../pages/login/Login.component';
import { InventoryPage } from '../../pages/inventory/Inventory.component';
import { ProductEntry } from '../../pages/product-entry/ProductEntry.component';

export const Router = () => {

    const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
    const router = useNavigate();

    const isProduction = useAtomValue(productionAtom);
    const [flags, setFlags] = useAtom(featureFlagsAtom);
    const [scanalytics, setScanalytics] = useAtom(scanalyticsAtom);
    const [scanalyticWindow, setScanalyticWindow] = useAtom(scanalyticsWindowAtom);
    const setLineItemFlagReasonsAtom = useSetAtom(lineItemFlagReasonsAtom);

    React.useEffect(() => {
        if (!currentUser) {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            if (user.first && user.last && user.id) {
                setCurrentUser(user);
            } else {
                router('/login');
            }
        }
    }, [currentUser]);

    React.useEffect(() => {
        ApiServer.getFeatureFlags(isProduction).then((flags) => {
            setFlags(flags);
        });
        ApiServer.getScanalytics().then((results) => {
            setScanalytics(results);
        });
        ApiServer.getIssueReasons().then((results) => {
            setLineItemFlagReasonsAtom(results);
        })
    }, []);

    const openSidebar = () => {
        if (flags['bs-scanalytics']) {
            setCurrentUser(null);
            localStorage.removeItem('user');
        } else if (flags['bs-scanalytic-window']) {
            setScanalyticWindow(!scanalyticWindow);
        }
    }

    const getCurrentScanalytics = () => {
        return scanalytics.find((item) => {
            return item.id === currentUser.id
        });
    }

    return (
        <div className='RoutesWrapper'>
            <div className='RouterHeader'>
                {
                    currentUser && scanalytics.length > 0 ?
                    <div className='ScanCountArea'>
                        <span className="Scans">Scans: #{getCurrentScanalytics().scans?.today}</span>    
                    </div> : <div></div>
                }
                <div className='WallblushImage'>
                    <img src={logo} alt="Wallblush Logo" />
                </div>
                {
                    currentUser ? 
                    <div className='UserArea'>
                        {
                            flags['bs-scanalytics'] ? 
                            <span className="Username" onClick={openSidebar}>
                                <>Logout {currentUser.first} {currentUser.last}</>
                            </span> : null
                        }
                        {
                            flags['bs-scanalytic-window'] ? 
                            <span onClick={openSidebar} className="hamburger">
                                <Hamburger />
                            </span> : null
                        }
                    </div> : <div></div>
                }
            </div>
            <div className='Routes'>
                <Routes>
                    <Route path="/" element={<MainComponent />} />
                    <Route path="/analytics" element={<AnalyticsPage />} />
                    <Route path="/login" element={<LoginComponent />} />
                    <Route path="/inventory" element={<InventoryPage />} />
                    <Route path="/product-entry" element={<ProductEntry />} />
                </Routes>
            </div>
            <div 
                className='ScanalyticsWindow'
                style={{ right: scanalyticWindow ? '0' : '-400px' }}
            >
                <div className='ScanalyticsInner'>
                    <Navigation></Navigation>
                </div>
            </div>
        </div>
    )
}